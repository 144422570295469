exports.components = {
  "component---src-components-tech-templates-tech-template-jsx": () => import("./../../../src/components/tech-templates/TechTemplate.jsx" /* webpackChunkName: "component---src-components-tech-templates-tech-template-jsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-application-development-jsx": () => import("./../../../src/pages/application-development.jsx" /* webpackChunkName: "component---src-pages-application-development-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-corporate-presentation-jsx": () => import("./../../../src/pages/corporate-presentation.jsx" /* webpackChunkName: "component---src-pages-corporate-presentation-jsx" */),
  "component---src-pages-custom-web-design-jsx": () => import("./../../../src/pages/custom-web-design.jsx" /* webpackChunkName: "component---src-pages-custom-web-design-jsx" */),
  "component---src-pages-digital-marketing-jsx": () => import("./../../../src/pages/digital-marketing.jsx" /* webpackChunkName: "component---src-pages-digital-marketing-jsx" */),
  "component---src-pages-domain-registration-jsx": () => import("./../../../src/pages/domain-registration.jsx" /* webpackChunkName: "component---src-pages-domain-registration-jsx" */),
  "component---src-pages-ecommerce-website-jsx": () => import("./../../../src/pages/ecommerce-website.jsx" /* webpackChunkName: "component---src-pages-ecommerce-website-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-page-website-designing-jsx": () => import("./../../../src/pages/landing-page-website-designing.jsx" /* webpackChunkName: "component---src-pages-landing-page-website-designing-jsx" */),
  "component---src-pages-logo-designing-jsx": () => import("./../../../src/pages/logo-designing.jsx" /* webpackChunkName: "component---src-pages-logo-designing-jsx" */),
  "component---src-pages-portfolio-jsx": () => import("./../../../src/pages/portfolio.jsx" /* webpackChunkName: "component---src-pages-portfolio-jsx" */),
  "component---src-pages-responsive-website-jsx": () => import("./../../../src/pages/responsive-website.jsx" /* webpackChunkName: "component---src-pages-responsive-website-jsx" */),
  "component---src-pages-seo-jsx": () => import("./../../../src/pages/seo.jsx" /* webpackChunkName: "component---src-pages-seo-jsx" */),
  "component---src-pages-static-web-design-development-services-jsx": () => import("./../../../src/pages/static-web-design-development-services.jsx" /* webpackChunkName: "component---src-pages-static-web-design-development-services-jsx" */),
  "component---src-pages-virtual-reality-jsx": () => import("./../../../src/pages/virtual-reality.jsx" /* webpackChunkName: "component---src-pages-virtual-reality-jsx" */),
  "component---src-pages-web-design-boston-jsx": () => import("./../../../src/pages/web-design-boston.jsx" /* webpackChunkName: "component---src-pages-web-design-boston-jsx" */),
  "component---src-pages-web-design-development-in-london-jsx": () => import("./../../../src/pages/web-design-development-in-london.jsx" /* webpackChunkName: "component---src-pages-web-design-development-in-london-jsx" */),
  "component---src-pages-web-design-development-in-losangeles-jsx": () => import("./../../../src/pages/web-design-development-in-losangeles.jsx" /* webpackChunkName: "component---src-pages-web-design-development-in-losangeles-jsx" */),
  "component---src-pages-web-design-development-in-tokyo-jsx": () => import("./../../../src/pages/web-design-development-in-tokyo.jsx" /* webpackChunkName: "component---src-pages-web-design-development-in-tokyo-jsx" */),
  "component---src-pages-web-design-paris-jsx": () => import("./../../../src/pages/web-design-paris.jsx" /* webpackChunkName: "component---src-pages-web-design-paris-jsx" */),
  "component---src-pages-web-design-sanjoes-jsx": () => import("./../../../src/pages/web-design-sanjoes.jsx" /* webpackChunkName: "component---src-pages-web-design-sanjoes-jsx" */),
  "component---src-pages-web-design-singapore-jsx": () => import("./../../../src/pages/web-design-singapore.jsx" /* webpackChunkName: "component---src-pages-web-design-singapore-jsx" */),
  "component---src-pages-web-designing-in-ankleshwar-jsx": () => import("./../../../src/pages/web-designing-in-ankleshwar.jsx" /* webpackChunkName: "component---src-pages-web-designing-in-ankleshwar-jsx" */),
  "component---src-pages-web-designing-in-baroda-jsx": () => import("./../../../src/pages/web-designing-in-baroda.jsx" /* webpackChunkName: "component---src-pages-web-designing-in-baroda-jsx" */),
  "component---src-pages-web-designing-in-bhavnagar-jsx": () => import("./../../../src/pages/web-designing-in-bhavnagar.jsx" /* webpackChunkName: "component---src-pages-web-designing-in-bhavnagar-jsx" */),
  "component---src-pages-web-designing-in-gandhinagar-jsx": () => import("./../../../src/pages/web-designing-in-gandhinagar.jsx" /* webpackChunkName: "component---src-pages-web-designing-in-gandhinagar-jsx" */),
  "component---src-pages-web-designing-in-jamnagar-jsx": () => import("./../../../src/pages/web-designing-in-jamnagar.jsx" /* webpackChunkName: "component---src-pages-web-designing-in-jamnagar-jsx" */),
  "component---src-pages-web-designing-in-mehsana-jsx": () => import("./../../../src/pages/web-designing-in-mehsana.jsx" /* webpackChunkName: "component---src-pages-web-designing-in-mehsana-jsx" */),
  "component---src-pages-web-designing-in-morbi-jsx": () => import("./../../../src/pages/web-designing-in-morbi.jsx" /* webpackChunkName: "component---src-pages-web-designing-in-morbi-jsx" */),
  "component---src-pages-web-designing-in-mumbai-jsx": () => import("./../../../src/pages/web-designing-in-mumbai.jsx" /* webpackChunkName: "component---src-pages-web-designing-in-mumbai-jsx" */),
  "component---src-pages-web-designing-in-pune-jsx": () => import("./../../../src/pages/web-designing-in-pune.jsx" /* webpackChunkName: "component---src-pages-web-designing-in-pune-jsx" */),
  "component---src-pages-web-designing-in-rajkot-jsx": () => import("./../../../src/pages/web-designing-in-rajkot.jsx" /* webpackChunkName: "component---src-pages-web-designing-in-rajkot-jsx" */),
  "component---src-pages-web-designing-in-surat-jsx": () => import("./../../../src/pages/web-designing-in-surat.jsx" /* webpackChunkName: "component---src-pages-web-designing-in-surat-jsx" */),
  "component---src-pages-web-development-packages-pricing-jsx": () => import("./../../../src/pages/web-development-packages-pricing.jsx" /* webpackChunkName: "component---src-pages-web-development-packages-pricing-jsx" */),
  "component---src-pages-web-hosting-jsx": () => import("./../../../src/pages/web-hosting.jsx" /* webpackChunkName: "component---src-pages-web-hosting-jsx" */),
  "component---src-pages-website-design-development-in-newyork-jsx": () => import("./../../../src/pages/website-design-development-in-newyork.jsx" /* webpackChunkName: "component---src-pages-website-design-development-in-newyork-jsx" */),
  "component---src-pages-website-design-houston-texas-jsx": () => import("./../../../src/pages/website-design-houston-texas.jsx" /* webpackChunkName: "component---src-pages-website-design-houston-texas-jsx" */),
  "component---src-pages-website-design-in-san-francisco-jsx": () => import("./../../../src/pages/website-design-in-san-francisco.jsx" /* webpackChunkName: "component---src-pages-website-design-in-san-francisco-jsx" */),
  "component---src-pages-website-designing-jsx": () => import("./../../../src/pages/website-designing.jsx" /* webpackChunkName: "component---src-pages-website-designing-jsx" */),
  "component---src-pages-what-we-do-jsx": () => import("./../../../src/pages/what-we-do.jsx" /* webpackChunkName: "component---src-pages-what-we-do-jsx" */),
  "component---src-pages-who-we-are-jsx": () => import("./../../../src/pages/who-we-are.jsx" /* webpackChunkName: "component---src-pages-who-we-are-jsx" */),
  "component---src-templates-blogss-jsx": () => import("./../../../src/templates/blogss.jsx" /* webpackChunkName: "component---src-templates-blogss-jsx" */)
}

